import { base_url } from './api';

const attemptLogin = async (email, password) => {
    const result = await fetch(`${base_url}/auth/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password })
    });

    let user = await result.json();
    user = user?.user;

    if (!user?.token) {
        return undefined;
    }

    return user;
};

const validateToken = async (token) => {
    const result = await fetch(`${base_url}/auth/validate/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ token })
    });

    return result.ok;
};

export { attemptLogin, validateToken };
