import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Button, debounce, MenuItem, Select, TextField } from '@mui/material';
import { shouldForwardProp } from '@mui/system';
import { Autocomplete } from '@mui/material';
import StyledBtn from '../../../../ui-component/styled/Button';
import { SET_POLICY, TOGGLE_SHOW_CHARTS } from '../../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import Search from '../../../../ui-component/icons/Search';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation, useNavigate } from 'react-router-dom';
import { getData } from '../../../../api/data';

const OutlineInputStyle = styled(TextField, { shouldForwardProp })(({ theme }) => ({
    width: '100%',
    flexGrow: 1,
    marginLeft: 16,
    padding: 0,
    background: 'transparent !important',
    borderRadius: '4px',
    '.MuiOutlinedInput-root': {
        padding: '0!important'
    },
    '& input': {
        minHeight: '54px',
        background: 'transparent !important',
        padding: '0 230px 0 49px !important',
        outline: 'none'
    },
    '& *': {
        background: 'transparent !important'
    }
}));

const Selected = styled(Select)(({ theme }) => ({
    width: 230,
    position: 'absolute',
    height: 48,
    right: '2px',
    background: theme.palette.background.default,
    top: '50%',
    border: 'none',
    outline: 'none',
    transform: 'translateY(-50%)',
    color: theme.palette.grey[400],
    '& *': {
        border: 'none!important',
        outline: 'none!important'
    },
    '& > *': {
        background: 'transparent!important',
        outline: 'none',
        boxShadow: 'none'
    },
    borderLeft: '1px solid #D2D2D2!important',
    borderRadius: '0 14px 14px 0!important',
    '& .MuiSelect-select': {
        fontSize: 16,
        color: theme.palette.grey[400],
        fontWeight: 400,
        letterSpacing: '0.5px'
    }
}));

const StyledIcon = styled(Box)(() => ({
    position: 'absolute',
    left: '19px',
    top: '55%',
    transform: 'translateY(-50%)'
}));

const StyledBox = styled(Box)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    position: 'relative',
    margin: '0 24px 0 50px'
}));

const SearchSection = () => {
    const [key, setKey] = useState(0);
    const [age, setAge] = useState('IT Business');
    const dispatch = useDispatch();

    const user = useSelector((state) => state.customization.user);
    const showCharts = useSelector((state) => state.customization.showSearchChart);
    const clickedPolicy = useSelector((state) => state.customization.policy);

    const [policy, setPolicy] = useState(clickedPolicy);
    const [policies, setPolicies] = useState([]);
    const { pathname } = useLocation();

    const navigate = useNavigate();

    const onInputChange = debounce((event, inputValue) => {
        setPolicy(inputValue);
    }, 50);

    const onSearchPress = () => {
        if (policy == null) {
            return;
        }
        if (pathname !== '/') {
            navigate('/');
        }
        dispatch({
            type: SET_POLICY,
            payload: {
                policy: { ...policy },
                showSearchCart: true
            }
        });
    };

    const onClearPress = () => {
        setPolicy(null);

        dispatch({
            type: TOGGLE_SHOW_CHARTS,
            payload: false
        });

        setKey(key + 1);
    };

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    useEffect(() => {
        const fetchData = async () => {
            const data = await getData(user);
            setPolicies(data['policies']);
        };

        fetchData();
    }, [user]);

    return (
        <StyledBox>
            <StyledBox sx={{ maxWidth: 800, position: 'relative', ml: 0 }}>
                <StyledIcon>
                    <Search />
                </StyledIcon>
                <Autocomplete
                    key={key}
                    sx={{ width: '100%' }}
                    disablePortal
                    id="search"
                    options={policies}
                    value={policy ? policy : null}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    popupIcon={null}
                    onChange={(event, newValue) => onInputChange(event, newValue)}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            onSearchPress();
                        }
                    }}
                    renderInput={(params) => <OutlineInputStyle {...params} />}
                />
            </StyledBox>
            <StyledBtn disabled={policy == null} variant="contained" sx={{ width: 104, height: 48 }} onClick={onSearchPress}>
                Search
            </StyledBtn>
            {showCharts ? (
                <Button sx={{ minWidth: 36, height: 36, ml: 1 }} onClick={onClearPress}>
                    <CloseIcon />
                </Button>
            ) : (
                <Box sx={{ width: 36 }} />
            )}
        </StyledBox>
    );
};

export default SearchSection;
