// project imports
import config from 'config';

// action - state management
import * as actionTypes from './actions';
import { RERENDER_MAP } from './actions';

export const initialState = {
    isOpen: [], // for active default menu
    defaultId: 'default',
    fontFamily: config.fontFamily,
    borderRadius: config.borderRadius,
    opened: false,

    data: undefined,
    user: undefined,

    policy: null,
    scoreType: 'wide',

    showSearchChart: false,
    rerenderMapView: false
};

const customizationReducer = (state = initialState, action) => {
    let id;
    switch (action.type) {
        case actionTypes.MENU_OPEN:
            id = action.id;
            return {
                ...state,
                isOpen: [id]
            };
        case actionTypes.SET_MENU: {
            return {
                ...state,
                opened: action.opened,
                rerenderMapView: !state.rerenderMapView
            };
        }
        case actionTypes.SET_FONT_FAMILY:
            return {
                ...state,
                fontFamily: action.fontFamily
            };
        case actionTypes.SET_BORDER_RADIUS:
            return {
                ...state,
                borderRadius: action.borderRadius
            };
        case actionTypes.SET_USER: {
            if (!action.user) {
                window.localStorage.removeItem('user');
            } else {
                window.localStorage.setItem('user', JSON.stringify(action.user));
            }

            return {
                ...state,
                user: action.user,
                policy: null,
                showSearchChart: false,
                rerenderMapView: false
            };
        }
        case actionTypes.SET_DATA: {
            return {
                ...state,
                data: action.payload
            };
        }
        case actionTypes.SET_POLICY: {
            return {
                ...state,
                policy: action.payload.policy,
                showSearchChart: action.payload.showSearchCart,
                rerenderMapView: !state.rerenderMapView
            };
        }
        case actionTypes.SET_SCORE_TYPE: {
            return {
                ...state,
                scoreType: action.payload.scoreType
            };
        }
        case actionTypes.TOGGLE_SHOW_CHARTS: {
            return {
                ...state,
                showSearchChart: action.payload,
                policy: action.payload ? state.policy : null
            };
        }
        case RERENDER_MAP:
            return {
                ...state,
                rerenderMapView: !state.rerenderMapView
            };
        default:
            return state;
    }
};

export default customizationReducer;
