import HomeIcon from '@mui/icons-material/Home';
const icons = { HomeIcon };
const dashboard = {
    id: 'dashboard',
    title: '',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Explore',
            type: 'item',
            url: '/',
            icon: icons.HomeIcon,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
