import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

const StyledBtn = styled(Button)(({ theme }) => ({
    background: theme.palette.green.default,
    fontSize: 15,
    '&:hover': {
        background: theme.palette.green.default,
        opacity: 0.8
    }
}));

export default StyledBtn;
