import PropTypes from 'prop-types';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import {
    Avatar,
    Card,
    CardContent,
    Grid,
    LinearProgress,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    linearProgressClasses,
    Button
} from '@mui/material';

import HelpIcon from '../../../../ui-component/icons/HelpIcon';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// styles
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 30,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: '#fff'
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.primary.main
    }
}));

const CardStyle = styled(Card)(({ theme, leftDrawerOpened }) => ({
    background: leftDrawerOpened ? theme.palette.green.default : 'transparent',
    marginBottom: '24px',
    overflow: 'hidden',
    position: 'relative',
    marginTop: 'auto'
}));

const CardHeading = styled(Typography)(({ theme }) => ({
    marginBottom: '24px',
    overflow: 'hidden',
    position: 'relative',
    background: 'none',
    color: '#fff',
    fontSize: 14,
    fontWeight: 700,
    margin: 0
}));

const CardButton = styled(Button)(({ theme }) => ({
    width: '100%',
    height: 40,
    borderRadius: 100,
    background: '#ffffff',
    color: '#45484F',
    textTransform: 'capitalize',
    transition: 'all 0.3s',
    '&:hover': { background: '#fff', opacity: 0.8 }
}));

// ==============================|| PROGRESS BAR WITH LABEL ||============================== //

function LinearProgressWithLabel({ value, ...others }) {
    const theme = useTheme();

    return (
        <Grid container direction="column" spacing={1} sx={{ mt: 1.5 }}>
            <Grid item>
                <CardHeading variant="h6">Need help?</CardHeading>
                <Typography variant="p" sx={{ color: '#fff', fontSize: 12 }}>
                    Write to our support team
                </Typography>
            </Grid>
            <Grid item>
                <CardButton>Proceed</CardButton>
            </Grid>
        </Grid>
    );
}

LinearProgressWithLabel.propTypes = {
    value: PropTypes.number
};

// ==============================|| SIDEBAR MENU Card ||============================== //

const MenuCard = () => {
    const theme = useTheme();
    const leftDrawerOpened = useSelector((state) => state.customization.opened);

    return (
        <CardStyle leftDrawerOpened={leftDrawerOpened}>
            <CardContent sx={{ padding: leftDrawerOpened ? '16px !important' : '0 !important' }}>
                <List sx={{ p: 0, m: 0 }}>
                    <ListItem alignItems={leftDrawerOpened ? 'flex-start' : 'center'} disableGutters sx={{ p: 0 }}>
                        <ListItemAvatar sx={{ mt: 0, display: 'flex', justifyContent: leftDrawerOpened ? 'flex-start' : 'center' }}>
                            <Link to={'/'}>
                                <HelpIcon />
                            </Link>
                        </ListItemAvatar>
                    </ListItem>
                </List>
                {leftDrawerOpened && <LinearProgressWithLabel value={80} />}
            </CardContent>
        </CardStyle>
    );
};

export default MenuCard;
