import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, useMediaQuery, SwipeableDrawer } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BrowserView, MobileView } from 'react-device-detect';
import MenuList from './MenuList';
import LogoSection from '../LogoSection';
import MenuCard from './MenuCard';
import { closedDrawerWidth, drawerWidth, headerHeight } from 'store/constant';

const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const drawer = (
        <>
            <BrowserView style={{ height: '100%' }}>
                <PerfectScrollbar
                    component="div"
                    style={{
                        height: '100%',
                        paddingLeft: '8px',
                        paddingRight: '8px',
                        paddingTop: '16px',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <MenuList />
                    <MenuCard />
                </PerfectScrollbar>
            </BrowserView>
        </>
    );

    return (
        <Box
            component="nav"
            sx={{ flexShrink: { md: 0 }, width: drawerOpen ? drawerWidth : closedDrawerWidth }}
            aria-label="mailbox folders"
        >
            <Box
                component="div"
                sx={{
                    width: '100%',
                    background: theme.palette.background.default,
                    color: theme.palette.text.primary,
                    borderRight: '1px solid #E3E3E3',
                    marginTop: `${headerHeight}px`,
                    height: `calc(100% - ${headerHeight}px)`
                }}
            >
                {drawer}
            </Box>
        </Box>
    );
};

Sidebar.propTypes = {
    drawerOpen: PropTypes.bool,
    drawerToggle: PropTypes.func,
    window: PropTypes.object
};

export default Sidebar;
