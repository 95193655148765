import RecomendetIcon from '../ui-component/icons/RecomendetIcon';
const icons = { RecomendetIcon };

const recommend = {
    id: 'recommend',
    type: 'group',
    children: [
        {
            id: 'recommend-page',
            title: 'Recommend',
            type: 'item',
            url: '/recommend',
            icon: icons.RecomendetIcon,
            breadcrumbs: false
        }
    ]
};

export default recommend;
