import * as React from 'react';
import { useTheme } from '@mui/material/styles';
const HelpIcon = (props) => {
    const theme = useTheme();
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={34} height={34} fill="none" {...props}>
            <rect width={34} height={34} fill="#fff" rx={17} />
            <path
                fill={theme.palette.green.default}
                d="M17 7C11.48 7 7 11.48 7 17s4.48 10 10 10 10-4.48 10-10S22.52 7 17 7Zm1 17h-2v-2h2v2Zm2.07-7.75-.9.92C18.45 17.9 18 18.5 18 20h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2h-2c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25Z"
            />
        </svg>
    );
};
export default HelpIcon;
