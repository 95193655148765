import Pricing from '../ui-component/icons/Pricing';
const icons = { Pricing };

const pricing = {
    id: 'pricing',
    type: 'group',
    children: [
        {
            id: 'pricing-page',
            title: 'Pricing',
            type: 'item',
            url: '/pricing',
            icon: icons.Pricing,
            breadcrumbs: false
        }
    ]
};

export default pricing;
