// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';
export const SET_USER = '@customization/SET_USER';
export const SET_DATA = '@customization/SET_DATA';
export const SET_POLICY = '@customization/SET_POLICY';

export const SET_SCORE_TYPE = '@customization/SET_SCORE_TYPE';

export const TOGGLE_SHOW_CHARTS = '@customization/TOGGLE_SHOW_CHARTS';

export const RERENDER_MAP = '@customization/RERENDER_MAP';
