import { lazy } from 'react';

import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const RecommendDefault = Loadable(lazy(() => import('views/recommend/')));
const PricingDefault = Loadable(lazy(() => import('views/pricing/')));

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: 'recommend',
            element: <RecommendDefault />
        },
        {
            path: 'pricing',
            element: <PricingDefault />
        }
    ]
};

export default MainRoutes;
