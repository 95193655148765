import { base_url } from './api';

export const getData = async (user) => {
    const result = await fetch(`${base_url}/data/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            token: user.token
        }),
        cache: 'force-cache'
    });

    const data = await result.json();

    return data;
};

export const getMap = async (user, map_name) => {
    const result = await fetch(`${base_url}/data/map/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            token: user.token,
            map_name: map_name
        }),
        cache: 'force-cache'
    });

    const mapData = await result.text();

    return mapData;
};
