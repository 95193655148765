import * as React from 'react';
const Pricing = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={16} fill="none" {...props}>
        <path
            fill="#000"
            fillOpacity={0.6}
            d="M18 0H2C.89 0 .01.89.01 2L0 14c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V2c0-1.11-.89-2-2-2Zm0 14H2V8h16v6Zm0-10H2V2h16v2Z"
        />
    </svg>
);
export default Pricing;
