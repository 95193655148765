import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CircularProgress, CssBaseline, StyledEngineProvider } from '@mui/material';
import themes from 'themes';
import NavigationScroll from 'layout/NavigationScroll';
import ProtectedRoute from './routes/ProtectedRoutes';
import LoginRoute from './routes/LoginRoutes';
import { SET_USER } from './store/actions';
import { useNavigate } from 'react-router';
import { validateToken } from './api/auth';

const App = () => {
    const customization = useSelector((state) => state.customization);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.customization.user);

    const [{ loading }, setState] = useState({ loading: true });

    const getUser = () => {
        return JSON.parse(window.localStorage.getItem('user')) || undefined;
    };

    useEffect(() => {
        const check_user = async () => {
            if (user) {
                return;
            }

            const logged_user = getUser();

            if (!logged_user || !(await validateToken(logged_user.token))) {
                setState((state) => ({
                    ...state,
                    loading: false
                }));

                dispatch({ type: SET_USER, user: null });
                navigate('/');
                return;
            }

            setState((state) => ({
                ...state,
                loading: false
            }));

            dispatch({ type: SET_USER, user: logged_user });
        };

        check_user();
    }, []);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    {loading && <CircularProgress color="success" />}
                    {!loading && user ? <ProtectedRoute /> : <LoginRoute />}
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
