import React from 'react';
import { Grid, Box, Typography, ListItem } from '@mui/material';
import { gridSpacing } from '../../../store/constant';
import { styled, useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';

const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    minHeight: 50
}));

const StyledTypografy = styled(Typography)(({ theme }) => ({
    fontSize: '14px',
    lineHeight: '150%',
    color: theme.palette.grey[300]
}));

const StyledLink = styled(Link)(({}) => ({
    marginLeft: 24,
    marginRight: 5,
    justifyContent: 'space-between',
    textDecoration: 'none',
    '&:hover': {
        opacity: 0.8
    }
}));

const Footer = () => {
    const date = new Date().getFullYear();

    return (
        <Grid container>
            <Grid item sx={{ flexGrow: 1 }}>
                <StyledBox>
                    <Box>
                        <StyledTypografy type={'p'}>@ {date}</StyledTypografy>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                        <StyledLink to={'/home'} underline="none" component="button">
                            <StyledTypografy type={'p'}>Blog</StyledTypografy>
                        </StyledLink>
                        <StyledLink to={'#'} underline="none">
                            <StyledTypografy type={'p'}>License</StyledTypografy>
                        </StyledLink>
                    </Box>
                </StyledBox>
            </Grid>
        </Grid>
    );
};

export default Footer;
